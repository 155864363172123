import { Injectable } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { pipe, tap } from 'rxjs';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { ServerAction } from './models/server-action.model';
import { WebsocketApiService } from './websocket-api.service';
import { AuthStorageService } from '../auth/services/auth-storage.service';
import { ServerActionsState } from './models/server-actions-state.model';

const INITIAL_STATE: ServerActionsState = {
  lastServerAction: null,
  serverActions: [],
};

@Injectable({
  providedIn: 'root',
})
export class ServerActionsStore extends signalStore(
  withState<ServerActionsState>(INITIAL_STATE),
  withMethods(store => ({
    getWebsocketMessage: rxMethod<ServerAction>(
      pipe(
        tap((websocketMessage: ServerAction) => {
          patchState(store, {
            lastServerAction: websocketMessage,
            serverActions: [...store.serverActions(), websocketMessage],
          });
        }),
      ),
    ),
    cleanState: () => patchState(store, INITIAL_STATE),
  })),
) {
  private connectionToken: string | null = null;
  constructor(
    private authStorageService: AuthStorageService,
    private websocketApi: WebsocketApiService,
  ) {
    super();
  }

  connect(url: string): void {
    const token = this.authStorageService.getToken();
    if (!token) {
      this.disconnect();
      return;
    } else if (!this.isConnectionAlreadyOpened(token)) {
      this.connectionToken = token;
      this.websocketApi.disconnect();
      this.cleanState();
      this.getWebsocketMessage(this.websocketApi.getServerActions$(token, url));
    }
  }
  disconnect(): void {
    this.connectionToken = null;
    this.websocketApi.disconnect();
    this.cleanState();
  }

  private isConnectionAlreadyOpened(token: string): boolean {
    return token === this.connectionToken;
  }
}
