import { Injectable } from '@angular/core';
import { webSocket } from 'rxjs/webSocket';
import { Observable, retry, shareReplay, Subject } from 'rxjs';
import { ServerAction } from './models/server-action.model';

@Injectable({
  providedIn: 'root',
})
export class WebsocketApiService {
  private readonly CONNECTION_RETRIES = 3;
  private websocketSubject: Subject<ServerAction> | null = null;

  getServerActions$(token: string, url: string): Observable<ServerAction> {
    this.websocketSubject = webSocket<ServerAction>({
      url,
      protocol: token,
    });
    return this.websocketSubject.asObservable().pipe(retry(this.CONNECTION_RETRIES), shareReplay(1));
  }

  disconnect() {
    this.websocketSubject?.complete();
  }
}
